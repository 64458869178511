/* eslint-disable react/no-danger */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import * as Scroll from "react-scroll"
import Layout from "components/Layout/Layout"
// import Countdown from "components/Countdown"
import { ModalToggle, Modal } from "components/Modal"
// import SpeakerGallery from "components/SpeakerGallery"
// import Collage from "components/Collage"
// import Tickets from "components/Tickets"
import NewsletterForm from "components/NewsletterForm"

import "./index.scss"

const IndexPage = ({ data: { scale19Json: indexPage } }) => (
  <Layout>
    <div id="hero" className="hero">
      <img
        className="hero__logo"
        src={indexPage.siteLogo.image.publicURL}
        alt={indexPage.siteLogo.alt}
      />
      <h1 className="hero__heading">{indexPage.conferenceTagline}</h1>
      {/* <h3 className="hero__subheading">
        <span className="hero__subheading__date">
          {indexPage.eventDate.display}
        </span>
        <br />
        <span className="hero__subheading__location">
          {indexPage.eventLocation.name}
        </span>
      </h3> */}
    </div>
    <Scroll.Element id="about" className="about">
      <h2>{indexPage.about.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: indexPage.about.text }} />
      <ModalToggle id="newsletter-modal">
        {openModal => (
          <button className="newsletter__cta" type="button" onClick={openModal}>
            {indexPage.newsletter.CTA.label}
          </button>
        )}
      </ModalToggle>
    </Scroll.Element>

    <div className="aftermovie">
      <h3 className="aftermovie__heading">{indexPage.afterMovie.heading}</h3>
      <div className="aftermovie__player">
        <Img fluid={indexPage.afterMovie.coverImg.childImageSharp.fluid} />
        <ModalToggle id="aftermovie-modal">
          {openModal => (
            <button
              className="aftermovie__player__play"
              onClick={openModal}
              type="button"
            />
          )}
        </ModalToggle>
        <Modal id="aftermovie-modal">
          {closeModal => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className="aftermovie__modal"
              onClick={closeModal}
              onKeyDown={closeModal}
            >
              <iframe
                title="this was scale18"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GlcIQ8fx3TI"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
        </Modal>
      </div>
    </div>
    {/* <Scroll.Element id="speakers" className="speakers">
      <h2>{indexPage.speakers.heading}</h2>
      <p dangerouslySetInnerHTML={{ __html: indexPage.speakers.text }} />
    </Scroll.Element>
    <SpeakerGallery /> */}
    {/* <Scroll.Element id="tickets" className="tickets">
      <h2>{indexPage.tickets.heading}</h2>
      <p
        className="tickets__description"
        dangerouslySetInnerHTML={{ __html: indexPage.tickets.text }}
      />
      <Countdown {...indexPage.tickets.countdown[0]} />
      <Tickets />
      <p>
        Questions? Check out our &nbsp;
        <Link to="/faq">FAQ</Link>
      </p>
    </Scroll.Element> */}
    <Scroll.Element id="contact" className="contact">
      <div className="newsletter">
        <h3>{indexPage.newsletter.heading}</h3>
        <p dangerouslySetInnerHTML={{ __html: indexPage.newsletter.text }} />
        <ModalToggle id="newsletter-modal">
          {openModal => (
            <button
              className="newsletter__cta"
              type="button"
              onClick={openModal}
            >
              {indexPage.newsletter.CTA.label}
            </button>
          )}
        </ModalToggle>
        <Modal id="newsletter-modal">
          {closeModal => (
            <div className="newsletter__modal">
              <NewsletterForm />
              <button
                type="button"
                onClick={closeModal}
                className="newsletter__modal__close"
              />
            </div>
          )}
        </Modal>
      </div>
      <div>
        <h3>{indexPage.contact.heading}</h3>
        <p dangerouslySetInnerHTML={{ __html: indexPage.contact.text }} />
      </div>
    </Scroll.Element>
    {/* <Collage /> */}
    {/* <div className="sponsors">
      <h2>{indexPage.sponsors.heading}</h2>
      <p dangerouslySetInnerHTML={{ __html: indexPage.sponsors.text }} />
    </div> */}
  </Layout>
)

export const query = graphql`
  query IndexPageQuery {
    scale19Json {
      siteLogo {
        image {
          publicURL
        }
        alt
      }
      conferenceTagline
      eventLocation {
        name
      }
      eventDate {
        display
      }
      about {
        heading
        text
      }
      afterMovie {
        heading
        coverImg {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      speakers {
        heading
        text
      }
      tickets {
        heading
        text
        countdown {
          label
          deadline
        }
      }
      newsletter {
        heading
        text
        CTA {
          label
        }
      }
      contact {
        heading
        text
      }
      collage {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sponsors {
        heading
        text
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default IndexPage
